import React from 'react';
import { AppBar, Drawer } from 'molecules';
import { Footer } from 'organisms';
import { SnackbarProvider } from 'notistack';
import { useRouter } from 'next/router';
import useStyles from './styles';

const LayoutWrapper = (props: LayoutWrapperPropTypes) => {
    const classes = useStyles();

    const { children } = props;

    const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);

    const router = useRouter();
    const ignorePaths = ['/500', '/404'];

    if (ignorePaths.includes(router.pathname)) {
        return <>{children}</>;
    } else {
        return (
            <SnackbarProvider
                iconVariant={{
                    success: '👍 ',
                    error: '👀 ',
                    info: 'ℹ️ ',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantInfo: classes.info,
                }}
            >
                <div className={classes.root}>
                    <AppBar
                        onMobileMenuClick={() => {
                            setDrawerIsOpen(true);
                        }}
                    />

                    <Drawer
                        open={drawerIsOpen}
                        onOpen={() => {
                            setDrawerIsOpen(true);
                        }}
                        onClose={() => {
                            setDrawerIsOpen(false);
                        }}
                        onMenuCloseClick={() => setDrawerIsOpen(false)}
                    />
                    <div className={classes.appbarOffset}>{children}</div>
                    <Footer />
                </div>
            </SnackbarProvider>
        );
    }
};

export interface LayoutWrapperPropTypes {
    children: React.ReactNode;
}

export default LayoutWrapper;
