import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
/**
 * NOTE: The following import is required to allow using typed lab components
 * for overrides/default props
 *
 * See: https://material-ui.com/components/about-the-lab/#typescript
 */
import type from '@material-ui/lab/themeAugmentation';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#060606',
        },
        primary: {
            main: '#f3b104',
        },
        secondary: {
            main: '#1f1f1f',
            light: '#365775',
        },
        common: {
            black: '#000000',
            white: '#FFFFFF',
        },
        success: { main: '#53b051' },
        info: { main: '#5c5c5c' },
        error: {
            main: '#f84242',
        },
        custom: {
            yellowOrange: '#f3b104',
            gold: '#f6b404',
            tangerine: '#ff8d04',
            coral: '#f84242',
            green: '#53b051',
            white27: 'rgba(255, 255, 255, 0.27)',
            white70: 'rgba(255, 255, 255, 0.70)',
            white16: 'rgba(255, 255, 255, 0.16)',
            white08: 'rgba(255, 255, 255, 0.08)',
            white04: 'rgba(255, 255, 255, 0.04)',
            darkGrey: '#5c5c5c',
            black: '#060606',
            surfaces: {
                atSurface: '#101010',
                aboveSurface: '#191919',
            },
        },
    },
    typography: {
        fontFamily: 'Overpass, sans-serif',
    },
    overrides: {
        MuiTypography: {
            colorTextPrimary: {
                color: 'rgba(255, 255, 255, 0.87)',
            },
            colorTextSecondary: {
                color: 'rgba(255, 255, 255, 0.57)',
            },
            h1: {
                fontWeight: 'bold',
                fontFamily: 'Chivo, sans-serif',
            },
            h2: {
                fontWeight: 'bold',
                fontFamily: 'Chivo, sans-serif',
            },
            h3: {
                fontWeight: 'bold',
                fontFamily: 'Chivo, sans-serif',
            },
            h4: {
                fontWeight: 'bold',
                fontFamily: 'Chivo, sans-serif',
            },
            h5: {
                fontWeight: 'bold',
                fontFamily: 'Chivo, sans-serif',
            },
            h6: {
                fontWeight: 'bold',
                fontFamily: 'Chivo, sans-serif',
            },

            subtitle1: {
                fontWeight: 'bold',
            },
            subtitle2: {
                fontWeight: 'bold',
            },
        },
    },
    props: {
        MuiSkeleton: {
            animation: false,
        },
    },
});

export default responsiveFontSizes(theme);
