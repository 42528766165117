import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: '100%',
            minHeight: '100vh',
        },
        appbarOffset: {
            // Place content slighly behind starting point of appbar
            marginTop: `calc(${
                (theme.mixins.toolbar.minHeight as number) + theme.spacing(5)
            }px)`,
        },

        // Notistack overrides
        success: {
            backgroundColor: `${theme.palette.success.main} !important`,
        },
        error: { background: `${theme.palette.error.main} !important` },
        info: { background: `${theme.palette.info.main} !important` },
    });

export default makeStyles(styles);
