import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize('UA-187950075-1');
};

export const logPageView = (url: string) => {
    ReactGA.set({ page: url });
    ReactGA.pageview(url);
};

export const logEvent = (event: any) => {
    ReactGA.event(event);
};
