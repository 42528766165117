import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import App, { AppProps } from 'next/app';
import Router from 'next/router';
import { initGA, logPageView } from 'lib/google/analytics';
import theme from 'theme';
import { NextSeo } from 'next-seo';
import 'react-medium-image-zoom/dist/styles.css';
import { LayoutWrapper } from 'hocs';
import './global.css';

export default class CustomApp extends App {
    constructor(props: AppProps) {
        super(props);
    }

    componentDidMount() {
        if (!window.GA_ANALYTICS && process.env.NODE_ENV === 'production') {
            initGA();
            window.GA_ANALYTICS = true;
            logPageView(window.location.pathname);
            Router.events.on('routeChangeComplete', logPageView);
        }
    }

    render() {
        const { Component, pageProps } = this.props;

        return (
            <>
                <NextSeo title={'Jena Snelling'} />
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <LayoutWrapper>
                        {/* The rest of your application */}
                        <Component {...pageProps} />
                    </LayoutWrapper>
                </ThemeProvider>
            </>
        );
    }
}
